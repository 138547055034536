import React, { useState, useEffect, useContext, FC } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import intl from "react-intl-universal";
import { getConfig, cortexFetch } from "@zilker/store-components";
import MessageContainer from "@zilker/store-components/src/MessageContainer/messagecontainer";
import { withRouter, RouteComponentProps } from "react-router-dom";
import contactUsFormValidor from "../validation/contactUsFormValidation";
import { MainContext } from "../contexts/MainContext";
import { checkTokensExpired, pushToMaintenace } from "../utils/helpers";

import "../theme/sharedClasses.less";
import "./ContactUsPageSF.less";

interface inputForm {
  brand: string;
  email: string;
  name: string;
  phone: string;
  company: string;
  subject: string;
  message: string;
  "record-type-id": string;
  "record-type": string;
  orgid: string;
  "captcha-settings": string;
  external: string;
}

interface formErrors {
  email: string;
  name: string;
  subject: string;
  message: string;
  phone?: any;
  company?: string;
}

interface infoMessage {
  debugMessages: string;
  id: string;
  type: string;
}

const ContactUsPageSF: FC<RouteComponentProps> = ({ history }) => {
  const context = useContext<{ auth: any; user: any; account: any }>(
    MainContext
  );
  const { config } = getConfig();
  const {
    cortexApi: { scope },
    displayRecaptcha,
    reCaptchaSiteKey,
    orgid,
    RecordTypeId
  } = config;
  const brand = scope.toUpperCase();

  const {
    user,
    auth: { logout },
    account
  } = context;
  const {
    userProfile: { givenName, familyName, phone, email }
  } = user;
  const {
    accountDetails: { customerNumber, company }
  } = account;

  const subjectOptions = [
    { key: "", value: intl.get("select-subject") },
    { key: "account_registration", value: intl.get("account-registration") },
    {
      key: "credentials_inquiry_support",
      value: intl.get("credentials-inquiry-support")
    },
    {
      key: "can_not_find_products",
      value: intl.get("can-not-find-products")
    },
    {
      key: "technical_website_issues",
      value: intl.get("technical-website-issues")
    },
    {
      key: "technical_product_help",
      value: intl.get("technical-product-help")
    },
    {
      key: "commercial_equipment_support",
      value: intl.get("commercial-equipment-support")
    },
    {
      key: "parts_and_supplies_support",
      value: intl.get("parts-and-supplies-support")
    },
    {
      key: "suggestions_site_feedback",
      value: intl.get("suggestions-site-feedback")
    },
    { key: "general_inquiry", value: intl.get("general-inquiry") },
    { key: "order_status", value: intl.get("order-status") },
    { key: "warranty_inquiry", value: intl.get("warranty-inquiry") }
  ];

  const captchaSettings = `{"keyname":"DaikinConsumerLead","fallback":"true","orgId":${orgid},"ts":""}`;

  const inputForm: inputForm = {
    brand,
    email: user.userProfile.email,
    name: `${user.userProfile.givenName} ${user.userProfile.familyName}`,
    phone: `${user.userProfile.phone}`,
    company: account.accountDetails.company
      ? `${account.accountDetails.company} - ${account.accountDetails.customerNumber}`
      : "",
    subject: "",
    message: "",
    "record-type-id": RecordTypeId,
    "record-type": RecordTypeId,
    orgid,
    "captcha-settings": "",
    external: "1"
  };

  const errorsInForm: formErrors = {
    email: "",
    name: "",
    subject: "",
    message: ""
  };

  const infoMessage: infoMessage = {
    debugMessages: "",
    id: "",
    type: ""
  };

  const [form, setForm] = useState(inputForm);
  const [formErrors, setErrors] = useState(errorsInForm);
  const [infoMsg, setInfoMsg] = useState(infoMessage);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  useEffect(() => {
    setForm(prevState => {
      const updatedState = { ...form };
      updatedState.name = `${givenName} ${familyName}`;
      updatedState.email = email;
      updatedState.phone = phone;
      updatedState.company = company ? `${company} - ${customerNumber}` : "";
      return { ...prevState, ...updatedState };
    });
  }, [givenName, familyName, email, company, customerNumber]);

  function inputHandler(e) {
    e.persist();
    setForm(prevState => {
      const updatedState = { ...form };
      updatedState[e.target.name] = e.target.value;
      return { ...prevState, ...updatedState };
    });
    setErrors(errorsInForm);
  }

  function closeMessageContainer() {
    setInfoMsg(infoMessage);
  }

  function isGuestUser() {
    return email === "";
  }

  const handleRecaptchaChange = value => {
    setRecaptchaValue(value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { errors, isValid } = contactUsFormValidor(form);
    let msg: infoMessage;
    if (!isValid) {
      setErrors(errors);
      return;
    }
    if (recaptchaValue || !displayRecaptcha) {
      const url = `/contactus/${scope}/form`;
      cortexFetch(url, {
        method: "post",
        body: JSON.stringify(form)
      })
        .then(res => {
          msg = {
            debugMessages: intl.get("thank-you-msg-contact-us"),
            id: "",
            type: "needinfo"
          };
          setInfoMsg(msg);
          setForm(inputForm);
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => handleSubmit => ContactUsPageSF.tsx"
              })
            );
          } else {
            msg = {
              debugMessages: intl.get("service-error"),
              id: "",
              type: "needinfo"
            };
            setInfoMsg(msg);
            setForm(inputForm);
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
          }
        });
    } else {
      alert("Please tick the reCAPTCHA.");
    }
  };

  return (
    <div
      className="viewport ui-container static-ui-container container-fluid contact-us-wrapper"
      data-region="viewPortRegion"
      style={{ display: "block" }}
    >
      <MessageContainer
        message={infoMsg}
        closeContainerHandler={closeMessageContainer}
      />

      <div id="formContainer" className="content-box">
        <form autoComplete="off" onSubmit={handleSubmit}>
          <input
            type="hidden"
            name="captcha_settings"
            value={captchaSettings}
          />
          <h4>{intl.get("contact-us")}</h4>
          <table className="contact-us-table">
            <tbody>
              <tr className="contact-us-table-row">
                <td className="contact-us-table-data">
                  <label htmlFor="email" className="label">
                    <span className="star">*</span>
                    {intl.get("email")}
                  </label>
                  <input
                    id="email"
                    maxLength={80}
                    name="email"
                    size={20}
                    type="email"
                    onChange={inputHandler}
                    value={form.email}
                    required
                    className={`${
                      formErrors && formErrors.email
                        ? "validation-error-border"
                        : null
                    }`}
                    pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                  />
                  {formErrors && formErrors.email ? (
                    <p className="validation-error"> {formErrors.email}</p>
                  ) : null}
                </td>
              </tr>
              <tr className="contact-us-table-row">
                <td className="contact-us-table-data">
                  <label htmlFor="name" className="label">
                    <span className="star">*</span>
                    {intl.get("name")}
                  </label>
                  <input
                    id="name"
                    maxLength={50}
                    name="name"
                    size={40}
                    type="text"
                    onChange={inputHandler}
                    value={form.name}
                    required
                    className={`${
                      formErrors && formErrors.name
                        ? "validation-error-border"
                        : null
                    }`}
                  />
                  {formErrors && formErrors.name ? (
                    <p className="validation-error"> {formErrors.name}</p>
                  ) : null}
                </td>
              </tr>
              <tr className="contact-us-table-row">
                <td className="contact-us-table-data">
                  <label htmlFor="phone" className="label">
                    {intl.get("phone")}
                  </label>
                  <input
                    id="phone"
                    maxLength={40}
                    name="phone"
                    size={20}
                    type="telephone"
                    pattern="^\(\d{3}\)\s\d{3}-\d{4}$|^\d{3}-\d{3}-\d{4}$|^\d{3}\.\d{3}\.\d{4}$|^\d{10}$"
                    onChange={inputHandler}
                    value={form.phone}
                    className={`${
                      formErrors && formErrors.phone
                        ? "validation-error-border"
                        : null
                    }`}
                  />
                  {formErrors && formErrors.phone ? (
                    <p className="validation-error"> {formErrors.phone}</p>
                  ) : null}
                </td>
              </tr>
              <tr className="contact-us-table-row">
                <td className="contact-us-table-data">
                  <label htmlFor="company" className="label">
                    {intl.get("company")}
                  </label>
                  <input
                    id="company"
                    maxLength={128}
                    name="company"
                    size={40}
                    type="text"
                    onChange={inputHandler}
                    value={form.company}
                    disabled={!isGuestUser()}
                    className={`${
                      formErrors && formErrors.company
                        ? "validation-error-border"
                        : null
                    }`}
                  />
                  {formErrors && formErrors.company ? (
                    <p className="validation-error"> {formErrors.company}</p>
                  ) : null}
                </td>
              </tr>
              <tr className="contact-us-table-row">
                <td className="contact-us-table-data">
                  <label htmlFor="subject" className="label">
                    <span className="star">*</span>
                    {intl.get("subject")}
                  </label>
                  <select
                    name="subject"
                    id="subject"
                    defaultValue=""
                    required
                    className={
                      formErrors.subject ? "validation-error-border" : null
                    }
                    onChange={inputHandler}
                    value={form.subject ? form.subject : ""}
                  >
                    {subjectOptions.map(option => (
                      <option key={option.key} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                  </select>
                  <p className="validation-error">
                    {formErrors.subject && formErrors.subject}
                  </p>
                </td>
              </tr>
              <tr className="contact-us-table-row">
                <td className="contact-us-table-data">
                  <label htmlFor="message" className="label">
                    <span className="star">*</span>
                    {intl.get("message")}
                  </label>
                  <textarea
                    name="message"
                    required
                    onChange={inputHandler}
                    value={form.message}
                    className={
                      formErrors.message ? "validation-error-border" : null
                    }
                  />
                  <p className="validation-error">
                    {formErrors.message && formErrors.message}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          {displayRecaptcha && (
            <ReCAPTCHA
              sitekey={reCaptchaSiteKey}
              onChange={handleRecaptchaChange}
            />
          )}
          <input
            className="dast-btn dast-btn-primary wide submit-btn"
            type="submit"
            value="Send"
          />
        </form>
      </div>
    </div>
  );
};

export default withRouter(ContactUsPageSF);
